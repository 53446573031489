@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

.testimonials {
	// box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	// -webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	// -moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	padding: 30px 50px 180px;
	position: relative;

	h3 {
		font-family: 'Noto Sans', cursive;
		font-size: 2em;
	}

	.image-wrapper {
		background: #666;
		border: 3px #fff solid;
		border-radius: 50px;
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		height: 100px;
		margin: -80px auto 10px;
		width: 100px;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

		img {
			display: block;
			height: 60px;
			margin: 0 auto 0 25px;
			opacity: 0.8;
			position: relative;
			top: 17px;
		}
	}

	.testimonial {
		background: rgba(0, 0, 0, .1);
		border: 2px #fff solid;
		border-radius: 10px;
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		padding: 20px 50px;
		font-style: italic;
		font-size: 1.2em;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

		img.quotes {
			display: block;
			height: 50px;
			opacity: 0.7;
			margin: -50px auto 20px;
		}

		p {
			color: rgba(0, 0, 0, .5);
		}

		&:after {
			content: '';
			position: absolute;
			bottom: -30px;
			left: 50%;
			width: 0;
			height: 0;
			border: 20px solid transparent;
			border-top-color: #00aabb;
			border-bottom: 0;
			border-left: 0;
			margin-left: -10px;
			margin-bottom: -20px;
		}
	}

	.speech-bubble {
		position: relative;
		background: rgba(0, 0, 0, .1);
		border-radius: .4em;
		color: rgba(0, 0, 0, .7);
		font-size: 1.3em;
		font-style: italic;
		padding: 20px 50px;

		img.quotes {
			display: block;
			height: 50px;
			opacity: 0.7;
			margin: -50px auto 20px;
		}
	}

	.speech-bubble:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 30%;
		width: 0;
		height: 0;
		border: 39px solid transparent;
		border-top-color: rgba(0, 0, 0, .1);
		border-bottom: 0;
		border-right: 0;
		margin-left: -19.5px;
		margin-bottom: -39px;
	}

	.owner {
		bottom: 80px;
		left: 380px;
		overflow: hidden;
		position: absolute;
		width: 600px;

		.avatar {
			border: 4px #fff solid;
			border-radius: 40px;
			box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			height: 80px;
			float: left;
			margin-right: 15px;
			overflow: hidden;
			width: 80px;
			-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

			img {
				height: 73px;
				left: 0;
				position: relative;

			}
		}

		h6 {
			color: rgba(0, 0, 0, .7);
			font-size: 1.2em;
			font-weight: normal;
			line-height: 0.8em;
			position: relative;
			top: 24px;

			strong {
				display: block;
				font-size: 1.4em;
			}
		}
	}
}
