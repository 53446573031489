.admin-search-filters {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.05);
	  color: #fff;
	  background-color:rgba(0, 0, 0, .3);
		margin-bottom: 0 !important;
	  padding: 15px;
		-webkit-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.05);
		-moz-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.05);



  .ant-radio-wrapper {
    color: #fff;
  }

  .module {
    &.rut-name {
      padding: 0 0 5px;
    }

    &.user {
    }

    &.dates {
      padding-top: 0;
    }

    &.certificate {
      .module-inner {
        padding-top: 10px;
      }
    }

    &.date-pickers {
      padding-top: 0;

      .module-inner {

      }
    }

    &.submit {
      padding-top: 21px;
      padding-left: 0px;
    }

    &.restore {
      padding-top: 21px;
      padding-left: 8px;
    }



    &.input {

    }

    .module-inner {
      padding: 5px 15px 5px 0;
    }

    &.outcome {
      padding-top: 1px;

      .module-inner {
        padding-top: 10px;

        .ant-radio {
          span {
            font-size: 0.1em !important;
            line-height: 0.5em;
          }
        }

      }
    }
  }
}
