.payment {
	background: rgba(216,221,240,1);
	background: -moz-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(216,221,240,1)), color-stop(19%, rgba(216,221,240,1)), color-stop(100%, rgba(237,241,255,1)));
	background: -webkit-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	background: -o-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	background: linear-gradient(to bottom, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8ddf0', endColorstr='#edf1ff', GradientType=0 );
	box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	min-height: 300px;
	padding-bottom: 55px;
	padding-top: 20px;

	.image-wrapper {
		background: #666;
		border: 3px #fff solid;
		border-radius: 50px;
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		height: 100px;
		margin: -70px auto 10px;
		width: 100px;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

		img {
			display: block;
			height: 40px;
			margin: 0 auto 0 23px;
			opacity: 0.8;
			position: relative;
			top: 30px;
		}
	}

	.methods {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		ul {
			list-style-type: none;
			margin: 0;
			overflow: hidden;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;
				margin: 0 10px;

				img {
					height: 20px;
				}
			}
		}
	}

	.big-button {
		padding-top: 20px;
		text-align: center;

		.ant-btn {
			background: green;
			border: 0;
			height: 40px;
			width: 400px;
		}
	}

	big {
		margin-bottom: 25px !important;
	}
}
