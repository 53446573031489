.services{

    margin-top: 18px;

    .image-wrapper {
		background: #D6DFFA;
		border: 3px #fff solid;
		border-radius: 50px;
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		height: 100px;
		margin: -70px auto 10px;
		width: 100px;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

		img {
			display: block;
			height: 74px;
			margin: 0px 0px 0px 12px;
			position: relative;
			top: 6px;
		}
	}
    .row-cards-wrapper{
        padding: 20px;
        margin: 50px 0px 150px 0px;
    }

    .service-tier-card{
        // border: black 1px solid;
        text-align: center;
        border-radius: 8px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        height: 240px;
        padding: 10px;
        .title-card-services{
            font-family: "Noto Sans", cursive;
            font-weight: 800;
            font-size: 20px;
        }
        .description-card-services{
            margin-bottom: 20px;
        }
        .tier-addons-service{
            text-align: left;
        }

        
    }
    .service-request-btn{
        display: flex;
        margin-top: -18px;
        margin-bottom: 40px;
        text-align: center;
        justify-content: center;
        flex-direction: column;

        .ant-btn-primary{
            background-color: #FD8204;
            border-color: #FD8204;
        }
    }
}

