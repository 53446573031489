@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

.homeTitle {
	background: #218CC8;
	padding: 30px 0 60px;
	text-align: center;

	strong {
		background: rgba(0, 0, 0, .2);
		border-radius: 5px;
		color: #fff;
		display: inline-block;
		font-weight: normal;
		margin-bottom: 5px;
		padding: 5px 10px;
		text-transform: uppercase;
	}

	h1 {
		font-family: 'Noto Sans', cursive;
		font-size: 2.8em;
		font-weight: bold;
		color: #fff;
		line-height: 1.3em;
		margin: 0;
		padding: 0 100px;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.35);
	}
}
