.content {
  background: rgba(255, 255, 255, .95);
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  margin: 35px auto;
  min-height: 800px;
	overflow: hidden;
  width: 90%;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
}
