
.buying-page{
    .paying-step-component{
        .image-handshake-pay-step{
            text-align: center;
        }
        .image-logos-pay-step{
                margin-top: 50px;
                padding-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    overflow: hidden;
                    padding: 0;
                    text-align: center;
                    li {
                        display: inline-block;
                        margin: 0 10px;
                        img {
                            height: 20px;
                        }
                    }
                }
        }
    }
}
