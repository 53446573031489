.purchase-confirmation-modal-inner {
	.purchase-confirmation-header {
		background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		height: 40px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		h1 {
			color: #fff;
			font-size: 1em;
			line-height: 40px;
			margin: 0 0 15px;
			padding: 0;
			text-indent: 15px;
		}
	}

	.wallet-notification {
		background: #fff8c6;
		border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
		border-radius: 5px;
		left: 0;
		margin-bottom: 15px;
		padding: 10px;
		text-align: center;

		&.no-money {
			//background:#be2a1b;
			background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			color: #fff;
		}
	}

	.request-ruts {
		background: rgba(0, 0, 0, .1);
		border-radius: 5px;
		overflow: hidden;

		table {
			border: 0;
			margin: 0;
			padding: 0;
			border-collapse: collapse;
			width: 100%;

			th {
				background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				color: #fff;
			}

			th, td {
				border: 1px rgba(255, 255, 255, .7) solid;
				font-size: 0.9em !important;
				padding: 8px 10px;
				font-size: 0.9em;
			}

			td, th {
			}

			tr:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		.wallet-price {
			background: rgba(0, 0, 0, .04);
			box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
			height: 30px;
			position: relative;
			-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
			-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);

			.wallet-amount {
				background: rgba(0, 0, 0, .05);
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				left: 0;
				text-align: left;
				width: 210px;

				span {
					font-size: 0.9em;
					padding-left: 10px;
				}
			}


			.price-label {
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				left: 0;
				text-align: right;
				width: 390px;

				span {
					font-size: 0.9em;
					padding-right: 10px;
				}

				.instructions {
					font-size: 0.7em;
					left: 40px;
					line-height: 1em;
					position: absolute;
					text-align: left;
					top: 5px;
					width: 250px;
				}
			}

			.price {
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-right: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				right: 0;
				text-align: right;
				width: 101px;

				span {
					font-size: 0.9em;
					padding-right: 10px;
				}
			}
		}
	}
	.confirm-button {
		background: green;
		border: 0;
		margin: 15px 0 0;
		width: 100%;
	}
	.cancel-button {
		background: gray;
		border: 0;
		margin: 5px 0 0;
		width: 100%;
	}
}

.purchase-confirmation-modal {
	.ant-modal-content {
		border-radius: 5px;
		overflow: hidden;

		.ant-modal-close-x {
			color: #fff;
			height: auto;
			line-height: 1em;
			position: absolute;
			right: 15px;
			top: 12px;
			width: auto;
		}
	}
	.ant-modal-body {
		padding: 54px 15px 15px;
	}
}
