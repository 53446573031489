.leftVideo {
	background: #fff;
	-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
	-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
	box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
	border-top: 1px rgba(255, 255, 255, .8) solid;
	border-radius: 10px;
	line-height: 1em;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 20px;
	margin-top: 0;
	overflow: hidden;
	padding: 4px 7px;
	position: relative;
	// top: -25px;
	height: 463px;

	iframe {
		border-radius: 8px;
		width: 100%;
	}
}
