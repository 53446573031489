.modal-purchase {
	padding: 0 0 20px 0 !important;
	height: 400px !important;
	position: relative;
	width: 300px !important;

	.ant-modal-close-x {
		display: none !important;
	}

	.ant-modal-body {
		padding: 0;
		position: relatve;

		.modal-purchase-content {
			.modal-purchase-content-inner {
				background: rgba(0, 0, 0, .1);
				border-radius: 5px;
				height: 200px;
				padding-top: 20px;
				text-align: center;
				width: 100%;

				.icon-circle {
					border-radius: 35px;
					color: white;
					font-size: 3em;
					height: 70px;
					left: 50%;
					margin: -40px 0 0 -35px;
					position: absolute;
					top: 50%;
					width: 70px;

					&.error {
						background: red;
					}

					&.success {
						background: green;
					}

					.anticon {
						line-height: 35px;
						position: relative;
						top: 5px;
					}
				}
			}
		}

		.ant-btn {
			background: green;
			bottom: 20px;
			display: block;
			left: 10px;
			margin: 0 10px;
			position: absolute;
			width: 260px;
		}
	}
}

/*
#successful-purchase {
	.ant-modal-close-x {
		display: none !important;
		border: 1px #f00 solid;
	}
}*/
