.purchase-item {
  background-image: linear-gradient(0deg, #fbfbfb 0%, #fff 100%);
  border-bottom: 1px rgba(0, 0, 0, .07) solid;
  padding: 0px 0px;

  &:hover {
    background-image: linear-gradient(0deg, #fdf6e1 0%, #fff 100%);
    cursor: pointer;
  }

  .avatar {
    position: absolute;
    align-items: center;
    background: rgba(0, 0, 0, .1);
    border-radius: 30px;
    box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);
    display: flex;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    width: 40px;
    left: 12px;
    -webkit-box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);
    -moz-box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);

    img {
      height: 30px;
      opacity: 0.5;
    }
  }

  .ant-col {
    align-items: center;
    display: flex;
    height: 60px;
  }

  .img-avatar {
    font-size: 24px;
    color: rgba(0,0,0,.4)
  }
}   //end of .purchase-item





  .modal-purchase-spinner {
    position: absolute;
    left: 50%;
    top: 85%;
  }

  .purchase-modal-list {
    margin-bottom: 2em;
  }

  .askUbosButton {
    .subTotal{

    }
    text-align: center;
  }

  .labelNoSaldo {
    color: red;
  }

  .ant-modal-body{
    //max-height: 200px;
  }
