.public {
  .login {
		.login-header {
			box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);
			height: 50px;
			line-height: 50px;
			-webkit-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);
			-moz-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);
		}
    .login-box {
			background: rgba(213,216,222,1);
			background: -moz-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(213,216,222,1)), color-stop(46%, rgba(171,177,190,1)), color-stop(100%, rgba(171,177,190,1)));
			background: -webkit-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
			background: -o-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
			background: -ms-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
			background: linear-gradient(to bottom, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d8de', endColorstr='#abb1be', GradientType=0 );
			-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			border-radius: 5px;
			box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			color: white;
			margin: 15px 15px 0;
			padding: 20px;

			.ant-btn {
				background: green;
				margin-top: 10px;
				width: 100%;
			}

			.ant-form-item {
				margin-bottom: 0;
			}

			h2 {
				line-height: 1em;
				margin: 0 0 10px;
			}
    }
  }

	.login-aside {
		border-left: 1px rgba(0, 0, 255, .05) solid;
		padding-top: 40px;
		text-align: center;

		img {
			display: block;
			margin: 0 auto 20px;
			width: 250px;
		}

		p {
			color: rgba(0, 0, 0, .5);
			margin-bottom: 50px;
		}

		.login-features {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0 0 0 170px;
				text-align: left;

				li {
					font-style: italic;
					margin-bottom: 20px;

					.anticon {
						background: green;
						border-radius: 8px;
						color: #fff;
						font-size: 0.6em !important;
						height: 16px;
						line-height: 19px;
						margin-right: 10px;
						position: relative;
						top: -2px;
						width: 16px;
					}
				}
			}
		}
	}
}
