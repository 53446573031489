.wallet {
	.top-block {
		background: #606c88; /* Old browsers */
		background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
		border-radius: 10px;
		margin-bottom: 35px;
		overflow: hidden;
		position: relative;

		.ant-col {
			height: 150px;
			position: relative;
		}

		.amount-col {
			small {
				color: rgba(255, 255, 255, .7);
				left: 103px;
				position: absolute;
				top: 30px;
			}

			img {
				display: block;
				float: left;
				height: 40px;
				left: 50px;
				position: absolute;
				top: 32px;
			}

			h1 {
				color: white;
				font-size: 1.8em;
				font-style: italic;
				left: 100px;
				line-height: 1.1em;
				padding: 0;
				position: absolute;
				text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
				top: 47px;
				width: 500px;
			}
		}

		.add-funds {
			#add-money-amount {
				border-bottom-right-radius: 0 !important;
				border-top-right-radius: 0 !important;
				position: absolute;
				right: 205px;
				top: 40px;
				width: 150px;
				z-index: 1000;
			}

			.ant-btn {
				background: green;
				border: 1px rgba(255, 255, 255, .2) solid;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				position: absolute;
				right: 60px;
				top: 40px;
				width: 150px;
				z-index: 1;
			}
		}
	}

	.payment-history-component {
		position: relative;

		h3 {
			background: black;
			border-radius: 20px;
			border: 2px rgba(255, 255, 255, .8) solid;
			box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.035);
			color: white;
			font-size: 1em;
			height: 30px;
			left: 50%;
			line-height: 15px;
			margin: 0 0 0 -150px;
			padding: 5px 16px;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			top: -15px;
			width: 300px;
			-webkit-box-shadow:  0px 5px 5px 0px rgba(0,0,0,0.035);
			-moz-box-shadow:  0px 5px 5px 0px rgba(0,0,0,0.035);
		}
	}

	.payment-methods {
		background: #f0f3fd;
		bottom: 0;
		box-shadow: inset 0px 10px 13px -15px rgba(0, 0, 0, 0.5);
		border: 1px #dfe3f2 solid;
		padding: 7px 0;
		position: absolute;
		text-align: center;
		width: 100%;
    -webkit-box-shadow: inset 0px 10px 13px -15px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0px 10px 13px -15px rgba(0, 0, 0, 0.5);

		& > div {
			display: inline-block;
			margin: 0 10px 0;

			img {
				height: 15px;
				margin: 0 0 3px;
				padding: 0;
			}
		}
	}
}
