
.information {
  .ant-row {
    // height: 25px
    padding: 0;
    margin: 0;
  }
}



.company-rut-row {
  position: relative;
}