.services-form{
    // padding: 0px 10px 0px 100px;
    .service-form-page-title{
        text-align: center;
        font-size: 45px;
        font-weight: 700;
        margin-top: 50px;
        font-family: 'Arvo', sans-serif;
    }

    .service-form-page-subtitle{
        font-size: 30px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        text-align: center;
    }
    .service-form-content{
        background-color: rgba(33, 120, 196, 0.089);
        padding: 50px;
        border-radius: 8px;
        margin-top: 50px;
        width: 100%;
        .service-form-content-title{
            font-weight: 700;
            font-size: 30px;
            color:rgba(0, 20, 136, 0.76);
            text-align: center;
        }
        .service-tier-card{
            border-radius: 8px;
            box-shadow: -12px 10px 22px 12px rgba(0, 0, 0, 0.2);
            padding: 20px 0px 40px 0px;
            background-color: white;
            .title-card-services{
                font-family: "Noto Sans", cursive;
                font-weight: 800;
                font-size: 30px;
                text-align: center;
            }
            .description-card-services{
                margin-bottom: 20px;
                text-align: center;
            }
            .tier-addons-service{
                text-align: left;
            }
    
            .service-request-btn{
                display: flex;
            }
            .service-form-title-card{
                font-weight: 800;
                font-family:'Times New Roman', Times, serif;
                color: rgb(124, 0, 240);
            }
            .service-form-element-wrapper{
                text-align: left;
            }

            .addon-item{
                margin-top: 8px;
                // margin-left:20px;
            }
        }
    }
}