.admin-page{
    .pagination-element-admin{
        margin-top:20px;
        margin-bottom: 60px;
        text-align: center;
    }

    .row-table-wrapper{
        padding: 30px;
    }
}