.payment-history {
  .first-col {
    text-indent: 20px;
  }

  .items-headers {
    background: rgba(0, 0, 0, .5);
    border-top: 1px rgba(0, 0, 0, .1) solid;
    box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    color: #fff;
    font-weight: bold;
    padding: 5px 0px;
    -webkit-box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    -moz-box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    text-align: justify;
  }

  .items {
    background: rgba(0, 0, 0, .03);
		border: 1px rgba(0, 0, 0, .05) solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
		margin-bottom: 20px;
    min-height: 400px;
		overflow: hidden;
    position: relative;

    .item {
      .abono {
        color: green;
      }
      .cargo {
        color: red;
      }

			&:last-child {
				box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.035);
				-webkit-box-shadow:  0px 5px 5px 0px rgba(0,0,0,0.035);
				-moz-box-shadow:  0px 5px 5px 0px rgba(0,0,0,0.035);
			}
    }


    .spinner {
      position: absolute;
      left: 50%;
      top: 10%;
    }

    .items-inner {
      box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      -webkit-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      -moz-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      font-size: 12px;

    }
  }
  .pagination {
    text-align: center;
  }

	.pagination:after {
		content: 'holaholahola';
	}

	.zero-items {
		height: 120px;
		left: 50%;
		margin: -60px 0 0 -90px;
		position: absolute;
		text-align: center;
		top: 50%;
		width: 180px;

		img {
			height: 100px;
		}

		big {
			color: rgba(0, 0, 0, .5);
			display: block;
			margin-top: 5px;
		}
	}
}
