.buying-page{
    .header-banner-buy-page{
        width: 100%;
        text-align: center;
        padding: 45px 0px 0px 0px;

        .type-buy-banner-name{
            font-size: 20.4px;
            font-style: normal;
            font-family: "Arvo", serif;
            text-transform: uppercase;
            font-weight: normal;
        }

        .type-buy-banner-subtitle{
            font-size: 36px;
            font-weight: 800;
            font-style: normal;
            font-family: "Arvo", serif;
            text-transform: uppercase;
            font-weight: normal;
        }
    }

    .buy-page-step-title{
        text-align: center;
        font-weight: bold;
    }


    .buy-page-steps-wrapper{
        padding: 40px;
    }

    .buy-page-card-wrapper{
        display: flex;
        align-items: center;
        vertical-align: middle;
        padding: 40px;
        .buy-page-product-card{
            // border: 1px rgba(0, 0, 0, 0.411) solid;
            border-radius: 8px;
            width: 100%;
            margin-top: 50px;
        }
        .buy-page-card-header{
            text-align: center;
            font-size: 20px;
            font-weight: 800;
            font-style: normal;
            font-family: "Arvo", serif;
            background-color: #218bc8;
            border-radius: 8px 8px 0px 0px;
            color: white;
            padding: 20px;
        }

        .buy-page-card-body{
            text-align: center;
            font-weight: 700;
            padding: 20px;
        }

        .buy-page-card-footer{
            background-color: #D4D8E7;
            padding: 20px;
            border-radius: 0px 0px 8px 8px;
            text-align: center;
            .buy-page-footer-price{
                font-weight: 800;
                font-size: 40px;
                font-family: "Arvo";
                margin-left: -20px;
            }
        }
    }

    .buy-page-steps-content{
        padding: 65px 50px 20px 50px;

        .ant-form-item {
            margin-bottom: 2px;

            .ant-form-item-label {
                line-height: 25px;
            }
            .ant-form-item-control {
                line-height: unset;
            }
        }

        .acciones {
            position: relative;
            div {
                position: absolute;
                bottom: 0px;
                padding: 4px 10px;
                width: 90px;

                button {
                    margin-right: 6px;
                }
            }
        }
    }

    .buy-page-steps-action{
        padding: 10px;
        text-align: center;
        .ant-btn-primary{
            background-color: #FD8204;
            border-color: #FD8204;
        }
    }

    .sending-to-payment-page{
        padding: 100px;
    }

}