.payment-item {
  background-image: linear-gradient(0deg, #fbfbfb 0%, #fff 100%);
  border-bottom: 1px rgba(0, 0, 0, .07) solid;
  padding: 0px 0px;

  &:hover {
    background-image: linear-gradient(0deg, #fdf6e1 0%, #fff 100%);
  }

	&:last-child {
		box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.035);
		-webkit-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.035);
		-moz-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.035);
	}

  .avatar-payment {
    position: absolute;
    align-items: center;
    background: rgba(0, 0, 0, .1);
    border-radius: 30px;
    box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);
    display: flex;
    height: 40px;
    left: 14px;
    justify-content: center;
    line-height: 40px;
    width: 40px;
    -webkit-box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);
    -moz-box-shadow: inset 0px -5px 3px -5px rgba(0,0,0,0.25);

    img {
      height: 22px;
			left: 1px;
      opacity: 0.5;
			position: relative;
			top: 1px;

			&.purchase-img {
				height: 19px;
				left: -1px;
				opacity: 0.4;
				position: relative;
			}
    }
  }

  .avatar-rut {
    display:inline-block;
    vertical-align: top;
    position: relative;
    .rut {
      position: absolute;
      left: 60px;
      display:flex;
    }
  }

  .abono {
    color: green;
  }
  .cargo {
    color: red;
  }


  .ant-col {
    align-items: center;
    display: flex;
    height: 60px;
  }

	.amount-col {
		display: block;
		line-height: 60px;
		padding-right: 15px;
		position: relative;
		text-align: right !important;
  }
  
  .orden-col {
		display: block;
		line-height: 60px;
		// padding-right: 15px;
		position: relative;
		text-align: left !important;
  }
  


}
