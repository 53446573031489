.purchase-history {
  .items-headers {
    background: rgba(0, 0, 0, .5);
    border-top: 1px rgba(0, 0, 0, .1) solid;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    color: #fff;
    font-weight: bold;
    padding: 5px 0px;
    -webkit-box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    -moz-box-shadow: inset 0px -5px 5px 0px rgba(0,0,0,0.07);
    text-align: justify;
  }

  .items {
    background: white;
    border-left: 1px rgba(0, 0, 0, .01) solid;
    border-right: 1px rgba(0, 0, 0, .01) solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 520px;
    position: relative;

    .spinner {
      position: absolute;
      left: 50%;
      top: 10%;
    }

    .items-inner {
      box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      -webkit-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      -moz-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.07);
      font-size: 12px;
    }
  }

  .pagination {
    text-align: center;
  }
}
