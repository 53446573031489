#b1, #b2 {
  // width: 100% !important;
  // display: inline-block !important;
  // margin-right: 5px;
}

.newRequest {
  display: flex;
  justify-content: space-between;


}


.ant-tooltip-inner {
  //position: relative;
  // left: -60px;
  // width: 350px;
}


