.leftDiv {
    height: 300px;
    h1 {
        text-align: center;
    }
    padding: 1em;
    p {
        font-size: 1em;
    }

}

.innerCardDiv {
    background-color: yellow;
    height: 200px;

}





