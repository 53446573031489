@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

.homeTitle {
	background: #218CC8;
	padding: 30px 0 60px;
	text-align: center;

	strong {
		background: #fd8104f6;
		border: 1px rgba(255, 255, 255, .5) solid;
		border-radius: 5px;
		-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		color: #fff;
		display: inline-block;
		font-weight: normal;
		margin-bottom: 5px;
		padding: 5px 10px;
		text-transform: uppercase;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
	}

	h1 {
		font-family: 'Noto Sans', cursive;
		font-size: 2.8em;
		font-weight: bold;
		color: #fff;
		line-height: 1.3em;
		margin: 0;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.35);
	}
	h2 {
		font-family: 'Noto Sans', cursive;
		font-size: 2.4em;
		font-weight: bold;
		color: #fff;
		line-height: 1.3em;
		margin: 0;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.35);
	}
	
	.ant-carousel .slick-slide {
		text-align: center;
		height: 160px;
		line-height: 160px;
		background: #364d79;
		overflow: hidden;
	}
}
