.tabs {
  border: 1px rgba(0, 0, 0, .1) solid;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 20px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px rgba(0, 0, 0, .1) solid;
      margin-bottom: 0;
      padding: 5px 8px;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.active {
        background: #608eeb;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
