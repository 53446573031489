.dashboard {
	.notifications-block {
		height: 80px;
		position: relative;

		.highlight-banner {
			background: #fff8c6;
			border: 1px rgba(0, 0, 0, .1) solid;
			border-radius: 5px;
			height: 65px;
			margin-bottom: 5px;
			margin-left: 20px;
			margin-right: 20px;
			position: absolute;
			width: 1060px;
			z-index: 300;

			&:last-child {
				margin-bottom: 20px;
			}

			.anticon {
				cursor: pointer;
				position: absolute;
				right: 15px;
				top: 15px;
			}

			.text {
				padding: 15px 20px;

				h3 {
					font-weight: bold;
					line-height: 1em;
					margin: 0;
					padding: 0;
				}

				p {
					margin: 0;
					padding: 0;
				}
			}
		}

		.ghost-notifications-group {
			.ghost-notification {
				background: #fff8c6;
				border: 1px rgba(0, 0, 0, .1) solid;
				border-radius: 5px;
				height: 65px;
				margin-bottom: 5px;
				margin-left: 20px;
				margin-right: 20px;
				position: absolute;
				width: 1060px;
				z-index: 150;

				& > div {
					z-index: 150;
				}

				&:nth-child(1) {
					left: 6px;
					top: -6px;
				}

				&:nth-child(2) {
					left: 3px;
					top: -3px;
				}
			}
		}
	}
}
