@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

big.products-block-title {
	font-family: 'Noto Sans', cursive;
	font-size: 2.5em;
	font-weight: bold;
	margin: 0px 0px 0px 0px;
	text-align: center;
	color: #174872;
}

h2.products-block-subtitle{
	font-family: 'Noto Sans', cursive;
	font-weight: bold;
	margin: 0px 0px 0px 0px;
	color: #174872;
}

.home-products {
	// background: rgba(216,221,240,1);
	// background: -moz-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(216,221,240,1)), color-stop(19%, rgba(216,221,240,1)), color-stop(100%, rgba(237,241,255,1)));
	// background: -webkit-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	// background: -o-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	// background: -ms-linear-gradient(top, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	// background: linear-gradient(to bottom, rgba(216,221,240,1) 0%, rgba(216,221,240,1) 19%, rgba(237,241,255,1) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8ddf0', endColorstr='#edf1ff', GradientType=0 );
	// box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	// -webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	// -moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);

	h3{
		display: block;
		font-family: 'Noto Sans', cursive;
		font-size: 1.5em;
		font-weight: bold;
		// height: 50px;
		left: 50%;
		// line-height: 1.3em;
		margin-left: -30%;
		margin-bottom: 70px;
		position: relative;
		text-align: center;
		width: 60%;
		color: #174872;
	}
	
	background-color: #F3F6FF;

	padding-bottom: 55px;
	padding-top: 20px;

	.image-wrapper {
		background: #D6DFFA;
		border: 3px #fff solid;
		border-radius: 50px;
		box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		height: 100px;
		margin: -70px auto 10px;
		width: 100px;
		-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

		img {
			display: block;
			height: 70px;
			margin: 0px 0px 0px 22px;
			position: relative;
			top: 11px;
		}
	}

	.home-products-wrapper {
		list-style-type: none;
		margin: 0;
		padding: 0px 50px 0px 50px;
		// margin: 0px 0px -98px 0px;

		.home-product {
			background: #fff;
			box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			border-radius: 10px;
			overflow: hidden;
			padding: 5px;
			position: relative;
			top: -25px;
			-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
			height: 100%;
			padding-bottom: 145px;

			.home-product-header {
				background: #218CC8;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;

				img {
					display: block;
					margin: 0 auto;
					position: relative;
					width: 60%;
					padding: 10%;
				}
			}

			.home-product-title {
				background: #889BCD;
				border: 2px #174872 solid;
				border-radius: 20px;
				box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
				color: #174872;
				font-size: 1.1em;
				left: 50%;
				letter-spacing: 0.04em;
				line-height: 35px;
				margin-left: -42%;
				margin-top: -20px;
				position: absolute;
				text-align: center;
				text-transform: uppercase;
				width: 85%;
				font-weight: 500;
				-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
				-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);

			}

			.home-product-description {
				background: #f7f8fc;
				border-top: 2px #fff solid;
				padding: 30px 30px 20px;
				text-align: center;
			}

			.home-product-features {
				margin-bottom: 40px;

				ul {
					list-style-type: none;
					padding: 25px 50px 0;

					li {
						background: transparent;
						border-radius: 0;
						box-shadow: none;
						float: none;
						font-size: 1em;
						font-style: italic;
						line-height: 1.5em;
						margin-bottom: 5px;
						min-height: auto;
						padding-left: 30px;
						position: relative;
						width: auto;
						margin-bottom: 10px;

						.anticon {
							background: #FD8204;
							border-radius: 9px;
							color: white;
							font-size: 0.7em;
							font-weight: 400;
							height: 18px;
							left: 0;
							line-height: 22px;
							position: absolute;
							top: 3px;
							width: 18px;
						}
					}
				}
			}

			.home-product-action {
				background: #eee;
				box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				padding: 15px;
				-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				position: absolute;
				width: calc(100% - 10px);
				bottom: 5px;

				.ant-btn {
					background: #FD8204;
					border: 0;
					height: 40px;
					width: 100%;
				}
			}

			.home-product-price {
				background: #D4D8E7;
				box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				color: rgba(0, 0, 0, .55);
				font-size: 1.3em;
				// font-weight: bold;
				padding: 10px 0;
				text-align: center;
				-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.25);
				position: absolute;
				width: calc(100% - 10px);
				bottom: 77px;
			}
		}
	}

	.clearer {
		clear: both;
	}
}
