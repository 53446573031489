.testimonieCard {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.img {
  border-radius: 50%;
}

.row {
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 60em;
}

.column1 {
  flex: 1;
  border-style: groove;
}

.column2 {
  flex: 6;
  border-style: groove;
}
