.dashboard-home {
	overflow: hidden;

	.products {
		overflow: hidden;

		.productos {
			list-style-type: none;
			margin: 0;
			padding: 0;

			.product {
				border: 1px rgba(0, 0, 0, .05) solid;
				border-radius: 10px;
				height: 100%;
				position: relative;
				padding-bottom: 150px;

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
				}
				.product-title {
					color: black;
					font-size: 1.1em;
					letter-spacing: 0.11em;
					position: relative;
					text-align: center;
					text-transform: uppercase;
					top: -20px;
					z-index: 1000;

					.product-title-inner {
						background: #889BCD;
						border: 2px black solid;
						border-radius: 20px;
						box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.07);
						padding: 5px;
						margin: 0 auto;
						-webkit-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.07);
						-moz-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.07);
					}
				}

				.product-header {
					background: #218CC8;
					padding: 20px 0 20px;
					border-radius: 8px 8px 0px 0px;

					img {
						display: block;
						margin: 0 auto;
						position: relative;
						width: 60%;
						padding: 10%;
					}
				}

				.product-description {
					background: #f0f3fd;
					border-top: 2px #fff solid;
					height: 100px;
					padding: 0 20px;
					position: relative;
					text-align: center;
					z-index: 999;

					span {
						border-bottom: 2px rgba(0, 0, 0, .15) solid;
					}

					p {
						padding: 10px 0;
						position: relative;
						top: -15px;
					}
				}

				.product-features {
					box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
					background: #fff;
					padding: 20px 0;
					-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
					-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);

					ul {
						list-style-type: none;
						margin: 0;
						padding: 0 35px;
						text-align: left;

						li {
							color: rgba(0, 0, 0, .4);
							line-height: 1.3em;
							margin-bottom: 10px;
							padding: 5px 0 2px 25px;
							position: relative;
							font-style: italic;

							.anticon {
								border-radius: 10px;
								color: #FD8204;
								font-size: 1.3em;
								left: 0;
								padding: 0;
								position: absolute;
								top: 7px;
							}

							span {
								border-bottom: 2px rgba(0, 0, 0, .15) solid;
							}
						}
					}
				}

				.product-total-price {
					background: #D4D8E7;
					box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.5);
					padding: 10px 0;
					text-align: center;
					-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.5);
					-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.5);
					position: absolute;
					bottom: 0px;
					width: 100%;
				}

				.product-footer {
					background: rgba(0, 0, 0, .07);
					box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
					overflow: hidden;
					padding: 15px 30px;
					position: absolute;
					width: 100%;
					bottom: 40px;
					min-height: 110px;
					-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
					-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);

					.rut-not-valid-error {
						animation-duration: 0.5s;
						animation-fill-mode: forwards;
						animation-name: errorAppears;
						background: red;
						color: white;
						left: 0;
						padding: 5px 0;
						position: absolute;
						text-align: center;
						top: 0;
						width: 100%;

						@keyframes errorAppears {
							from {
								top: -40px;
							}

							to {
								top: 0;
							}
						}
					}

					ul.input-collection {
						& > li {
							animation-duration: 0.25s;
							animation-fill-mode: forwards;
							animation-name: inputAppears;
							position: relative;
							margin-bottom: 5px;

							@keyframes inputAppears {
								from {
									background-color: rgba(255, 248, 198, .1);
									opacity: 0;
									transform: translateX(-100%) scaleX(0);
								}

								to {
									background-color: rgba(255, 248, 198, 0);
									opacity: 1;
									transform: translateX(0) scaleX(100%);
								}
							}

							.ant-input {
								width: 100%;
							}

							.ant-form-item {
								line-height: 1em;
								margin: 0;
								padding: 0;

								.ant-form-item-control {
									line-height: 1em;
								}
							}

							.anticon {
								background: #FD8204;
								border-radius: 10px;
								color: #fff;
								cursor: pointer;
								height: 20px;
								line-height: 22px;
								width: 20px;

								svg {
									position: relative;
									left: 0.05em;
								}
							}

							.action-item {
								display: none;
								font-size: 0.9em;
								// height: 20px;
								position: absolute;
								// text-align: center;
								top: 6px;
								// width: 20px;

								&.add-item {
									right: 6px;
								}

								&.delete-item {
									right: 29px;

									i {
										background: rgba(0, 0, 0, .5);
									}
								}
							}

							&:hover {
								.action-item {
									display: block;
								}
							}

							&:first-child:hover {
								.action-item {
									display: block;
								}

								.delete-item {
									display: none;
								}
							}
						}

						.is-valid {
							position: absolute;
							right: -25px;
							top: 5px;

							.anticon {
								background: transparent;
								color: green;
							}

							&.not-valid {
								.anticon {
									color: red;
								}
							}
						}
					}

					.ant-btn {
						background: #FD8204;
						border: 0;
						display: block;
						font-weight: bold;
						width: 100%;
					}
				}
			}
		}
	}
}

.purchase-confirmation-modal-inner {
	.purchase-confirmation-header {
		background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		height: 40px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		h1 {
			color: #fff;
			font-size: 1em;
			line-height: 40px;
			margin: 0 0 15px;
			padding: 0;
			text-indent: 15px;
		}
	}

	.wallet-notification {
		background: #fff8c6;
		border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
		border-radius: 5px;
		left: 0;
		margin-bottom: 15px;
		padding: 10px;
		text-align: center;

		&.no-money {
			background: #be2a1b;
			color: #fff;
		}
	}

	.request-ruts {
		background: rgba(0, 0, 0, .1);
		border-radius: 5px;
		overflow: hidden;

		table {
			border: 0;
			margin: 0;
			padding: 0;
			border-collapse: collapse;
			width: 100%;

			th {
				background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				color: #fff;
			}

			th, td {
				border: 1px rgba(255, 255, 255, .7) solid;
				font-size: 0.9em !important;
				padding: 8px 10px;
				font-size: 0.9em;
			}

			td, th {
			}

			tr:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		.wallet-price {
			background: rgba(0, 0, 0, .04);
			box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
			height: 30px;
			position: relative;
			-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);
			-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.3);

			.wallet-amount {
				background: rgba(0, 0, 0, .05);
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				left: 0;
				text-align: left;
				width: 210px;

				span {
					font-size: 0.9em;
					padding-left: 10px;
				}
			}


			.price-label {
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				left: 0;
				text-align: right;
				width: 390px;

				span {
					font-size: 0.9em;
					padding-right: 10px;
				}

				.instructions {
					font-size: 0.7em;
					left: 40px;
					line-height: 1em;
					position: absolute;
					text-align: left;
					top: 5px;
					width: 250px;
				}
			}

			.price {
				border-left: 1px rgba(255, 255, 255, .7) solid;
				border-right: 1px rgba(255, 255, 255, .7) solid;
				border-top: 1px rgba(255, 255, 255, .7) solid;
				height: 30px;
				line-height: 30px;
				position: absolute;
				right: 0;
				text-align: right;
				width: 101px;

				span {
					font-size: 0.9em;
					padding-right: 10px;
				}
			}
		}
	}
}

.purchase-confirmation-modal {
	.ant-modal-content {
		border-radius: 5px;
		overflow: hidden;

		.ant-modal-close-x {
			color: #fff;
			height: auto;
			line-height: 1em;
			position: absolute;
			right: 15px;
			top: 12px;
			width: auto;
		}
	}
	.ant-modal-body {
		padding: 54px 15px 15px;
	}
}
