.top-bar {
  box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);
  height: 80px;
  margin-bottom: 20px;
  padding: 0 20px;
	position: relative;
  -webkit-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);
  -moz-box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.035);



  .smiley-icon {
    color: rgba(0, 0, 0, .3);
    float: left;
    font-size: 2.5em;
    margin-right: 20px;
    position: relative;
    top: 23px;
  }

  h1 {
    float: left;
    line-height: 80px;
    margin: 0;
    padding: 0;
  }

	.wallet {
		position: absolute;
		left: 45%;
		line-height: 40px;
		top: 20px;

		.wallet-inner {
			background: rgba(0, 0, 0, .05);
			border-radius: 5px;
			float: right;
			height: 43px;
			padding: 2px 13px;

			strong {
				float: left;
			}

			.ant-btn {
				float: right;
				font-size: 0.8em;
				height: auto;
				margin-left: 5px;
				margin-top: 9px;
				padding: 2px 10px;
			}
		}
	}



  .ant-btn {
    background: green;
    border-color: green;
    border-radius: 20px;
    font-weight: bold;
    height: 40px;
    margin-top: 20px;
    float: right;
  }
}


.my-tooltip{
  border: 3px dashed #f00;
  background-color: blue;
  // position: relative;
  // left: -60px;
  // width: 350px;
}




#the-one{
  position: relative;
  left: -60px;
  width: 350px;
  }