.rightForm {
// 	background: rgba(213,216,222,1);
// 	background: -moz-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
// 	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(213,216,222,1)), color-stop(46%, rgba(171,177,190,1)), color-stop(100%, rgba(171,177,190,1)));
// 	background: -webkit-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
// 	background: -o-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
// 	background: -ms-linear-gradient(top, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
// 	background: linear-gradient(to bottom, rgba(213,216,222,1) 0%, rgba(171,177,190,1) 46%, rgba(171,177,190,1) 100%);
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d8de', endColorstr='#abb1be', GradientType=0 );
// 	-webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
// 	-moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
// 	box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.33);
// 	border-top: 1px rgba(255, 255, 255, .8) solid;
// 	border-radius: 10px;
// 	height: 400px;
// 	overflow: hidden;
// 	position: relative;
	padding: 12px 34px 42px 34px;
	background-color: #E5ECFF;
	border-radius: 10px;

.ant-form-explain {
	font-size: 0.8em;
}

.has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover, .has-error .ant-input, .has-error .ant-input:hover {
    background-color: #fff;
    border-color: transparent !important;
		border: 0;
}

	.ant-form-item {
		margin-bottom: 0;
	}


// 	h3 {
// 		color: rgba(0, 0, 0, .6);
// 		font-weight: normal;
// 	}

	.ant-btn {
		background: #218CC8;
		margin: 8px 0 0;
		width: 100%;
		border-radius: 10px;
	}

// 	.ant-input {
// 		border-left: 2px rgba(0, 0, 0, .3) solid;
// 		border-top: 2px rgba(0, 0, 0, .3) solid;
// 	}

// 	.hr {
// 		border-bottom: 1px rgba(255, 255, 255, .3) solid;
// 		border-top: 1px rgba(0, 0, 0, .1) solid;
// 		height: 0;
// 		margin: 5px 0 10px;
// 	}

// 	.white-overlay {
// 		background: #fff;
// 		bottom: 0;
// 		display: none;
// 		left: 0;
// 		position: absolute;
// 		right: 0;
// 		top: 0;
// 	}

	&.highlighted {
		.white-overlay {
			animation-duration: 1.5s;
			animation-fill-mode: forwards;
			animation-name: highlightForm;
			display: block;

			@keyframes highlightForm {
				0% {
					opacity: 0;
				}

				50% {
					opacity: 0.9;
				}

				100% {
					opacity: 0;
				}
			}
		}
	}

	input:focus {
		animation-duration: 0.5s;
		animation-fill-mode: forwards;
		animation-name: inputFocus;

		@keyframes inputFocus {
			to {
				background-color: #fff8c6;
			}
		}
	}

	.free-stuff-block {
		text-align: center;
		h1 {
			color: #218CC8;
			line-height: 1.2em;
		}
	}
}
