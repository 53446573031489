$defaultHeight: 50px;
$animationSpeed: 0.5s;

.header {
  box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.07);
  height: $defaultHeight;
  overflow: hidden;

  .header-inner {
    height: 100px;

    .public {
			//background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
			//background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
			background: #1D6F9F;
			color: #fff;
			height: $defaultHeight;
    }

    .private {
			//background: -moz-linear-gradient(top, #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
			//background: -webkit-linear-gradient(top, #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
			//background: linear-gradient(to bottom, #606c88 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			background: #1D6F9F;
			color: #fff;
      height: $defaultHeight;
    }
  }

  &.is-logged-out {
    .header-inner {
      animation-duration: $animationSpeed;
      animation-fill-mode: forwards;
      animation-name: header-logout-animation;
      transform: translateY(0);

      @keyframes header-logout-animation {
        to {
          transform: translateY(-50px);
        }
      }
    }

    .ant-btn {
			background-color: transparent;
			border: 0;
			border-left: 1px rgba(255, 255, 255, .5) solid;
			border-radius: 0;
			color: #fff;
			display: block;
      float: right;
			height: 50px;
			margin: 0;
			padding: 0 20px;
    }
  }

  &.is-logged-in {
    .header-inner {
      animation-duration: $animationSpeed;
      animation-fill-mode: forwards;
      animation-name: header-login-animation;
      transform: translateY(-$defaultHeight);

      @keyframes header-login-animation {
        to {
          transform: translateY(0);
        }
      }

      h1, .ant-btn {
        color: #fff;
        float: right;
      }

			.ant-btn {
				background-color: transparent;
				border: 0;
				border-left: 1px rgba(255, 255, 255, .5) solid;
				border-radius: 0;
				color: rgba(255, 255, 255, .9);
				display: block;
				float: right;
				height: 50px;
				margin: 0;
				padding: 0 20px 0 16px;

				&.username {
					i {
						background: rgba(255, 255, 255, .3);
						border-radius: 9px;
						font-size: 0.8em;
						font-weight: bold;
						height: 18px;
						position: relative;
						text-align: center;
						top: 3px;
						width: 18px;

						svg {
							left: 3.5px;
							position: absolute;
							top: 3px;
						}
					}
				}
			}
    }
  }
}
