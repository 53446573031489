.logo {
  height: 35px;
	left: 10px;
  position: absolute;
  top: 7px;
	z-index: 1001;

  img {
    height: 35px;
  }
}
