.footer {
  background: #218CC8;
	box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	padding: 0 0 0;
	-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);

	.footer-inner {
		margin: 10auto;
		overflow: hidden;
		padding: 20px 30px;
		position: relative;
		// width: 1100px;

		.logo {
			float: left;
			height: auto;
			margin-bottom: 15px;
			position: static !important;
			width: 220px;

			img {
				height: 60px;
			}
		}

		.company-info {
			clear: left;
			width: 100%;
			h3{
				color: white;
				font-style: italic;
			}

			p{
				color: white;
			}
			
			.footer-rrss{
				a{
					color: white;
					padding-right: 10px;
				}
				font-size: 30px;
			}

			.security-policy{
				font-size: 16px;
				color: #FD8204;
				font-weight: 700;
				letter-spacing: 5px;
				cursor: pointer;
			}

			// ul {
			// 	list-style-type: none;
			// 	margin: 0;
			// 	padding: 0;

			// 	li {
			// 		color: white;
			// 		font-size: 0.9em;
			// 		line-height: 22px;

			// 		img {
			// 			display: inline-block;
			// 			height: 12px;
			// 			margin-right: 5px;
			// 			margin-top: -2px;
			// 		}
			// 	}
			// }
		}

		.navigation {
			// overflow: hidden;
			// position: relative;
			// top: 7px;
			// width: 100%;

		 	& > div {
				// float: left;
				// margin: 0 13px;
				// width: 150px;

				&:first-child {
				}

				&:last-child {
					margin-right: 0;
				}

				h3 {
					border-bottom: 1px rgba(255, 255, 255, .5) solid;
					color: white;
					font-size: 0.9em;
					margin-bottom: 1em;
					padding-bottom: 5px;
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;

					li {
						color: rgba(255, 255, 255, .7);
						font-size: 0.9em;
						line-height: 1.2em;
						margin-bottom: 5px;
						padding-left: 12px;
						position: relative;

						span:hover {
							cursor: pointer;
							text-decoration: underline;
						}


						.bul {
							left: 0;
							position: absolute;
						}

						.anchor {
							color: rgba(255, 255, 255, 0.7);

						}

					}
				}
			}
			.sucursal{
				// padding-left: 10px;
				// width: 50%;
				a{
					color: white;
				}
			}
			.know-us{
				// width: 30%;
				a{
					color: white;
				}
			}
		}
	}

	.copy {
		background: rgba(0, 0, 0, .2);
		border-top: 1px rgba(0, 0, 0, .1) solid;
		color: rgba(255, 255, 255, .9);
		font-size: 0.9em;
		padding: 10px 0;
		text-align: center;
		margin-top: 20px;
		-webkit-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
		-moz-box-shadow: inset 0px 10px 13px -15px rgba(0,0,0,0.4);
	}
}

.footer-content {
	background: rgba(0, 0, 0, .5);
	border-radius: 10px !important;
	padding: 0 !important;
	top: 20px !important;

	&.is-native {
		width: 500px !important;
	}

	&.is-external {
		width: 1100px !important;
	}

	.ant-modal-close-x {
		font-size: 0.9em;
		height: auto !important;
		line-height: 1em !important;
		position: relative;
		right: 6px;
		top: 7px;
		width: auto !important;
	}

	.iframe-wrapper {
		height: 600px;

		iframe {
			border: 0;
			height: 100%;
			width: 100%;
		}
	}

	.content-wrapper {
		background: rgba(0, 0, 0, .1);
		border-radius: 10px;
		height: 500px;
		margin-bottom: 15px;
		overflow-y: scroll;
		padding: 10px;
	}

	.understood-button {
		.ant-btn {
			background: green;
			width: 100%;
		}
	}
}

.write-us {
	span {
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.anchor {
		color: rgba(255, 255, 255, 0.7);

	}
}
